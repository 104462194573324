import groq from 'groq'

import { blockFragment, simpleGridBlockFragment } from './blocks'
import { blogPostWithoutBodyFragment } from './blog'
import {
  cartSettingsFragment,
  collectionGridCartFragment,
  searchResultGridCartFragment,
} from './cart'
import {
  complexPortableTextFragment,
  simplePortableTextFragment,
} from './content'
import { imageFragment } from './image'
import { productFragment } from './product'
import { muxVideoFragment, videoFragment } from './video'

// Blog author post grid Module
const blogAuthorPostGridModuleFragment = groq`
  title,
  $blogAuthorSlug != null => {
    "author": *[
      _type == "blogAuthor" &&
      locale == $locale &&
      slug.current == $blogAuthorSlug
    ][0].name,
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      locale == $locale &&
      author->slug.current == $blogAuthorSlug
    ]
      | order(_createdAt desc)
      | order(date desc)
    {
      ${blogPostWithoutBodyFragment}
    },
  },
`

// Blog category post grid module
const blogCategoryPostGridModuleFragment = groq`
  title,
  $blogCategorySlug != null => {
    "category": *[
      _type == "blogCategory" &&
      locale == $locale &&
      slug.current == $blogCategorySlug
    ][0].name,
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      locale == $locale &&
      categories[]->slug.current match $blogCategorySlug
    ]
      | order(_createdAt desc)
      | order(date desc)
    {
      ${blogPostWithoutBodyFragment}
    },
  },
`

// Blog post body module
const blogPostBodyModuleFragment = groq`
  spacing,
  content[] {
    ${complexPortableTextFragment}
  },
`

// Blog post grid module
const blogPostGridModuleFragment = groq`
  title,
  "posts": *[
    _type == "blogPost" &&
    type != "template" &&
    locale == $locale &&
    (!defined(^.author) || author->slug.current == ^.author->slug.current) &&
    (!defined(^.category) || categories[]->slug.current match ^.category->slug.current)
  ]
    | order(_createdAt desc)
    | order(date desc)
  {
    ${blogPostWithoutBodyFragment}
  },
`

// Blog post header module
const blogPostHeaderModuleFragment = groq`
  spacing,
  $blogPostSlug != null => {
    "post": *[
      _type == "blogPost" &&
      type != "template" &&
      locale == $locale &&
      slug.current == $blogPostSlug
    ]
      | order(_updatedAt desc)
    [0] {
      ${blogPostWithoutBodyFragment}
    },
  },
`

// Blog post item module
const blogPostItemModuleFragment = groq`
  ...,
  post->{
    ${blogPostWithoutBodyFragment}
  },
`

// Blog post read more module
const blogPostReadMoreModuleFragment = groq`
  $blogPostSlug != null => {
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      locale == $locale &&
      slug.current != $blogPostSlug
    ] {
      "matchingCategoryCount": count((categories[]._ref)[
        @ in *[
          _type == "blogPost" &&
          type != "template" &&
          locale == $locale &&
          slug.current == $blogPostSlug
        ]
          | order(_updatedAt desc)
        [0].categories[]._ref
      ]),
      ${blogPostWithoutBodyFragment}
    }
      | order(_createdAt desc)
      | order(date desc)
      | order(matchingCategoryCount desc)
    [0...2],
  },
`

// Cart form module
const cartFormModuleFragment = groq`
  active,
  "cartSettings": *[
    _type == "cartSettings" &&
    locale == $locale
  ][0] {
    ${cartSettingsFragment}
  },
`

// Collection grid module
const collectionGridFragment = groq`
  title,
  expandProducts,
  onlyDiscounts,
  showQuickFilters,
  "ids": featuredProducts[]->productID,
  $collectionSlug != null => {
    "collection": *[
      _type == "collection" &&
      slug.current == $collectionSlug &&
      locale == $locale
    ][0] {
      title,
      "featuredProductIds": products[]->productID,
      "products": *[
        _type == "product" &&
        locale == $locale &&
        _id in ^.products[]._ref &&
        !wasDeleted &&
        !isDraft
      ] {
        ${productFragment}
      },
    },
  },
  "cartSettings": *[
    _type == "cartSettings" &&
    locale == $locale
  ][0] {
    ${collectionGridCartFragment}
  },
  "combinedListings": *[
    _type == "productCombinedListing" &&
    locale == $locale
  ] {
    _id,
    title,
    "productIds": products[]._ref,
  },
`

// Divider photo module
const dividerPhotoModuleFragment = groq`
  photo {
    ${imageFragment}
  },
`

// Grid module
const gridModuleFragment = groq`
  ...,
  columns[] {
    ...,
    sizes[] {
      ...,
    },
    blocks[] {
      ${blockFragment}
    },
  },
`

// Hero module
const heroModuleFragment = groq`
  bgType,
  contentPosition,
  content[] {
    ${complexPortableTextFragment}
  },
  bgType == "photo" => {
    photos {
      mobilePhoto {
        ${imageFragment}
      },
      desktopPhoto {
        ${imageFragment}
      },
    },
  },
  bgType == "lottie-animation" => {
    "lottieAssetUrl": lottieFile.asset->url,
  },
  bgType == "mux-video" => {
    ${muxVideoFragment}
  },
`

// Marquee module
const marqueeModuleFragment = groq`
  ...,
  content[] {
    ${complexPortableTextFragment}
  },
  items[] {
    ...,
    _type == "photo" => {
      ${imageFragment}
    },
    _type == "product" => {
      "product": *[
        _type == "product" &&
        _id == ^._ref
      ][0] {
        ${productFragment}
      },
    },
  },
`

// Product hero module
const productHeroModuleFragment = groq`
  active,
  "product": *[
    _type == "product" &&
    locale == $locale &&
    slug.current == $productSlug &&
    !wasDeleted &&
    !isDraft
  ]
    | order(_updatedAt desc)
  [0] {
    ${productFragment}
  },
  shoppingInfoActive == true => {
    "shoppingInfo": *[
      _type == "shoppingInfo" &&
      locale == $locale
    ][0] {
      content[] {
        ${simplePortableTextFragment}
      },
    },
  },
`

// Related products module
const relatedProductsModuleFragment = groq`
  active,
  $productSlug != null => {
    "products": *[
      _type == "product" &&
      locale == $locale &&
      !wasDeleted &&
      !isDraft &&
      !(_id in coalesce(
        *[
          _type == "productCombinedListing" &&
          locale == $locale &&
          *[
            _type == "product" &&
            slug.current == $productSlug
          ][0]._id in products[]._ref
        ][0].products[]->_id,
        []
      )) &&
      collections[0]->slug.current != null &&
      collections[0]->slug.current == *[
        _type == "product" &&
        slug.current == $productSlug
      ][0].collections[0]->slug.current
    ]
      | order(_updatedAt desc)
    [0...4] {
      ${productFragment}
    },
    "backupProducts": *[
      _type == "product" &&
      locale == $locale &&
      !wasDeleted &&
      !isDraft &&
      !(_id in coalesce(
        *[
          _type == "productCombinedListing" &&
          locale == $locale &&
          *[
            _type == "product" &&
            slug.current == $productSlug
          ][0]._id in products[]._ref
        ][0].products[]->_id,
        []
      )) &&
      collections[0]->slug.current != null &&
      collections[0]->slug.current == ^.backupCollection->slug.current
    ]
      | order(_updatedAt desc)
    [0...4] {
      ${productFragment}
    },
  },
`

// Search result grid
const searchResultGridModuleFragment = groq`
  expandProducts,
  "cartSettings": *[
    _type == "cartSettings" &&
    locale == $locale
  ][0] {
    ${searchResultGridCartFragment}
  },
`

// Grid module
const simpleGridModuleFragment = groq`
  ...,
  blocks[] {
    ${simpleGridBlockFragment}
  },
`

// Team members grid module
const teamMembersGridModuleFragment = groq`
  ...,
  "teamMembers": *[
    _type == "teamMember" &&
    locale == $locale
  ] | order(_createdAt asc) {
    ...,
    blogAuthor->{
      slug,
    },
    photo {
      ${imageFragment}
    },
  },
`

const baseModuleFragment = groq`
  _key,
  _type,
  _type == "blogAuthorPostGrid" => {
    ${blogAuthorPostGridModuleFragment}
  },
  _type == "blogCategoryPostGrid" => {
    ${blogCategoryPostGridModuleFragment}
  },
  _type == "blogPostBody" => {
    ${blogPostBodyModuleFragment}
  },
  _type == "blogPostGrid" => {
    ${blogPostGridModuleFragment}
  },
  _type == "blogPostHeader" => {
    ${blogPostHeaderModuleFragment}
  },
  _type == 'blogPostItem' => {
    ${blogPostItemModuleFragment}
  },
  _type == "blogPostReadMore" => {
    ${blogPostReadMoreModuleFragment}
  },
  _type == "cartForm" => {
    ${cartFormModuleFragment}
  },
  _type == "collectionGrid" => {
    ${collectionGridFragment}
  },
  _type == "dividerPhoto" => {
    ${dividerPhotoModuleFragment}
  },
  _type == "grid" => {
    ${gridModuleFragment}
  },
  _type == "hero" => {
    ${heroModuleFragment}
  },
  _type == "marquee" => {
    ${marqueeModuleFragment}
  },
  _type == "productHero" => {
    ${productHeroModuleFragment}
  },
   _type == "relatedProducts" => {
    ${relatedProductsModuleFragment}
  },
  _type == "searchResultGrid" => {
    ${searchResultGridModuleFragment}
  },
  _type == "simpleGrid" => {
    ${simpleGridModuleFragment}
  },
  _type == "teamMembersGrid" => {
    ${teamMembersGridModuleFragment}
  },
  _type == "video" => {
    ${videoFragment}
  },
`

const predefinedPageModuleModuleFragment = groq`
  "pageModules": pageModule->pageModules[] {
    ${baseModuleFragment}
  },
`

export const moduleFragment = groq`
  ${baseModuleFragment}
  _type == 'predefinedPageModule' => {
    ${predefinedPageModuleModuleFragment}
  },
`
